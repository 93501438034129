<template>
	<div>
		<div class="form-box">
			<el-input placeholder="入力して下さい" v-model="searchFriend">
				<template slot="append">
					<el-button type="danger" @click="serachProject()">検索</el-button>
				</template>
			</el-input>
		</div>
		<div class="main" v-if="getRole">
			<div class="title">
				<!-- 我的项目 -->
				<span>私のプロジェクト</span>
			</div>
			<div class="tab-box">
				<el-table :data="tableData" :header-cell-style="headClass" border style="width: 100%">
					<!-- 项目名称 -->
					<el-table-column prop="title" label="漫画タイトル" align="center" width="220">
						<template scope="scope">
							<span class="style" @click="toDetails(scope.row,true)"> {{ scope.row.title }} </span>
						</template>
					</el-table-column>
					<el-table-column prop="nickname" label="作家" align="center" width="180">
					</el-table-column>
					
					<!-- 最终更新 -->
					<el-table-column prop="updated_at" align="center" label="最終更新時間">
						<template scope="scope">
							<span class=""> {{ scope.row.update_at || "-" }} </span>
						</template>
					</el-table-column>
					
					<!-- 修改登录信息 -->
					<el-table-column prop="id" label="登録情報修正" align="center">
						<template scope="scope">
							<el-button @click="modifyProjectInfo(scope.row)" type="text" icon='el-icon-s-tools'
								size="small">変更</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- <div class="page-box">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="10"
					layout="total, prev, pager, next, jumper" :total="count">
				</el-pagination>
			</div> -->
			<div class="new-project-btn" v-if="getRole">
				<!-- 新项目立项 -->
				<span @click="goNewProject">+ プロジェクト作成</span>
			</div>
		</div>
		<div class="main" v-if="isShowPartList">
			<div class="title">
				<!-- 我参与的项目 -->
				<span>参加したプロジェクト</span>
			</div>
			<div class="tab-box">
				<el-table :data="table_Data" :header-cell-style="headClass" border style="width: 100%">
					<!-- 项目名称 -->
					<el-table-column prop="title" label="漫画タイトル" align="center" width="220">
						<template scope="scope">
							<span class="style" @click="toDetails(scope.row,false)"> {{ scope.row.title }} </span>
						</template>
					</el-table-column>
					<!-- 作者/作者名 -->
					<el-table-column prop="nickname" label="原作者" align="center" width="180">
					</el-table-column>
					
					<!-- 最终更新 -->
					<el-table-column prop="updated_at" align="center" label="最終更新時間">
						<template scope="scope">
							<span class="style"> {{ scope.row.update_at || "-" }} </span>
						</template>
					</el-table-column>
					<!-- 校对截止日 -->
					<el-table-column prop="end_at" align="center" label="校了締切日">
						<template scope="scope">
							<span class="style"> {{ scope.row.end_at || "-" }} </span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange_"
					:current-page="currentPage_" :page-sizes="[10, 20, 30, 40]" :page-size="10"
					layout="total,  prev, pager, next, jumper" :total="count_">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import service from '@/service'
import utils from '../../../assets/js/utils';
export default {
	data() {
		return {
			tableData: [],
			currentPage: 1,
			currentPage_: 1,
			count: 0,
			count_: 0,
			role: [],
			searchFriend: '',
			table_Data: []
		}
	},
	mounted() {
		this.getProjectList({ p: 1, keyword: this.searchFriend });
		this.role = utils.getStorage('userInfo').data.role.split(",")
		this.getPartProList ({  p: 1, keyword: this.searchFriend })
	},
	computed: {
		getRole() {
			if (this.role.indexOf('1') > -1) {
				return true
			} else {
				return false
			}
		},
		isShowPartList() {
			if (this.role.indexOf('2') > -1 || this.role.indexOf('4') > -1 || this.role.indexOf('3') > -1 || this.role.indexOf('5' >-1)) {
				return true
			} else {
				return false
			}
		},
	},
	methods: {
		headClass() {
			return "text-align: center"
		},
		goNewProject() {
      this.$router.push({
        path: "/personal-center/personal-index/creat-project",
        query: {
          id: 0,
        },
      });
    },
		getProjectList(data) {
			service.personal.getProjectList(data).then(res => {
				if (res.status === 200) {
					this.tableData = res.data;
					this.count = res.count;
				}
			})
		},
		getPartProList(data) {
			service.personal
				.getPartProList(data)
				.then((res) => {
					if (res.status === 200) {
						this.table_Data = res.data;
						this.count_ = res.count;
						
					}
				})
				.catch((err) => {
					console.log(err)
				});
		},
		toDetails(data,beanl) {
			this.$router.push({
				path: "project-details",
				query: { pid: data.id, id: 1 ,showTrue:beanl},
			});
		},
		//修改项目信息
		modifyProjectInfo(row) {
			
			this.$router.push({
				path: '/personal-center/personal-index/creat-project',
				query: {
					id: 0,
					pid:row.id
					// params: JSON.stringify(row)
				},
			})
		},
		handleSizeChange(val) {
			console.log(`毎頁 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.getProjectList({ p: val, keyword: this.searchFriend })
		},
		handleCurrentChange_(val) {
			this.getPartProList({ p: val, keyword: this.searchFriend })
		},
		serachProject() {
			if(this.role.indexOf('1') > -1) {
				this.getProjectList({ p: 1, keyword: this.searchFriend })
			}
			if(this.role.indexOf('2') > -1 || this.role.indexOf('4') > -1 || this.role.indexOf('3') > -1) {
				this.getPartProList({ p: 1, keyword: this.searchFriend })
			}
		}
	}
}

</script> 
<style lang="scss" scoped>
.form-box {
	padding: 0 40px;
	margin-top: 10px;
}

.main {
	padding: 20px 40px;

	.style {
		color: #1875d2;
		cursor: pointer;
	}

	.title {
		margin-bottom: 20px;

		span {
			color: #333;
			font-size: 15px;
			font-weight: bold;
			letter-spacing: 1px;
		}
	}

	.page-box {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
	}
	.new-project-btn {
		display: flex;
		justify-content: center;
		margin: 40px 0;

		span {
		display: block;
		width: 165px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		border-radius: 4px;
		background-color: #fff;
		border: 1px solid #f03747;
		color: #f03747;
		font-weight: bold;
		cursor: pointer;
		letter-spacing: 1px;
    }
  }
}
</style>